import FooterPage from '../../components/Footer';
import HeaderPage from '../../components/HeaderPage';
import HeaderPageNew from '../../components/HeaderPageNew';
import MainContent from './MainContent';

function PromotionBanner() {
  return (
    <section
      data-layername='get50OffOnFirstPurchase'
      className='gap-2.5 self-stretch p-2.5 w-full text-xl tracking-tight leading-none text-center text-white bg-[radial-gradient(163.05%_110.33%_at_0%_30.85%,#7ED957_0%,#20B364_100%)] max-md:max-w-full'
      role='banner'
      aria-label='Promotion Banner'
    >
      <strong className='font-black text-white'>Get 50% off</strong>{' '}
      <span>on First Purchase</span>
    </section>
  );
}

export default function HomePageNew() {
  return (
    <>
      <PromotionBanner />
      <HeaderPageNew />
      <div
      // style={{
      //   display: 'flex',
      //   flexDirection: 'column',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      // }}
      // className='max-sm:px-2'
      ></div>
      <MainContent />
      <FooterPage />
    </>
  );
}
