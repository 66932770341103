import React from 'react';
import { APP_STORE_URL, PLAY_STORE_URL } from '../../../utils/appConstant';

function Logo() {
  return (
    <img
      loading='lazy'
      src='https://cdn.builder.io/api/v1/image/assets/TEMP/5e6eb89e5de75e0d3221130585d6accbde532a8157e152738f9a4546b4734c27?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
      className='object-contain max-w-full aspect-[3.92] w-[110px]'
      alt='Company Logo'
    />
  );
}

function ContactInfo() {
  return (
    <div className='flex flex-col mt-8 w-full max-md:max-w-full'>
      <div className='flex flex-col w-full text-sm text-white max-md:max-w-full'>
        <h2 className='font-semibold max-md:max-w-full'>Address:</h2>
        <p className='mt-1 max-md:max-w-full'>
          #247/4 Habitech Nest 3rd Floor, ECity Bangalore- 560100
        </p>
      </div>
      <div className='flex flex-col mt-6 w-full text-sm text-white max-md:max-w-full'>
        <h2 className='font-semibold max-md:max-w-full'>Contact:</h2>
        <div className='flex flex-col mt-1 w-full max-md:max-w-full'>
          <a href='tel:+917892962859' className='underline max-md:max-w-full'>
            +91 7892962859
          </a>
          <a
            href='mailto:support@aluma.salon'
            className='underline max-md:max-w-full'
          >
            support@aluma.salon
          </a>
        </div>
      </div>
    </div>
  );
}

function SocialIcons() {
  const socialIcons = [
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/08bd06543e88a558b4def9e20bd28f9b5752ef6fc2e633ef05973be78e4799eb?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
      alt: 'Facebook',
      gotoUrl: 'https://www.instagram.com/aluma.salon/',
    },
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/8bb9f12861f2133acf2c6e9c8ea03fb36a25aa2839a4029a0a6e63efc307ab20?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
      alt: 'Instagram',
      gotoUrl: 'https://www.instagram.com/aluma.salon/',
    },
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/f3f56f6f00e29dc8a48d8d1274912545af0bfb8d20631a284e206a4cd0d1d54e?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
      alt: 'Twitter',
      gotoUrl: 'https://www.linkedin.com/company/aluma-salon/',
    },
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/855d889d6bf16cab80e682a7edc8274051d672e7c9ae49420b40ecc619e00fdb?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
      alt: 'LinkedIn',
      gotoUrl: 'https://www.linkedin.com/company/aluma-salon/',
    },
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b2f775afa212d3ce9aaa4e161f2d624da47b41af258238230a42e436094086a4?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
      alt: 'YouTube',
      gotoUrl: 'https://www.linkedin.com/company/aluma-salon/',
    },
  ];

  return (
    <div className='flex gap-3 items-start self-start mt-6'>
      {socialIcons.map((icon, index) => (
        <img
          key={index}
          loading='lazy'
          src={icon.src}
          className='object-contain shrink-0 w-6 aspect-square'
          alt={icon.alt}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (icon.gotoUrl) window.open(icon.gotoUrl);
          }}
        />
      ))}
    </div>
  );
}

function FooterLinks() {
  const links = [
    ['Link One', 'Link Two', 'Link Three', 'Link Four', 'Link Five'],
    ['Link Six', 'Link Seven', 'Link Eight', 'Link Nine', 'Link Ten'],
  ];

  return (
    <nav className='flex gap-8 items-center pl-1.5 w-full text-base font-semibold text-white'>
      {links.map((column, columnIndex) => (
        <div
          key={columnIndex}
          className='flex flex-col self-stretch my-auto w-[133px]'
        >
          {column.map((link, linkIndex) => (
            <a key={linkIndex} href='#' className={linkIndex > 0 ? 'mt-3' : ''}>
              {link}
            </a>
          ))}
        </div>
      ))}
    </nav>
  );
}

function PaymentOptions() {
  return (
    <div className='flex gap-6 items-start self-start mt-9'>
      <img
        loading='lazy'
        src='https://cdn.builder.io/api/v1/image/assets/TEMP/88b181f0140cb8b397a27a7f17728c5d34d50c1a57009844c2050d491cea43f7?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
        className='object-contain shrink-0 aspect-[3.31] w-[126px] cursor-pointer'
        alt='Payment option 1'
        onClick={() => window.open(PLAY_STORE_URL)}
      />
      <img
        loading='lazy'
        src='https://cdn.builder.io/api/v1/image/assets/TEMP/ab8cd858a74ead28a7fd055d34f628bdfbc5f362fd6fa047f93adfe920064bb2?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
        className='object-contain shrink-0 w-32 aspect-[3.37] cursor-pointer'
        alt='Payment option 2'
        onClick={() => window.open(APP_STORE_URL)}
      />
    </div>
  );
}

function Copyright() {
  return (
    <div className='flex flex-col mt-20 w-full text-sm text-white max-md:mt-10 max-md:max-w-full'>
      <hr className='w-full bg-white border border-white border-solid min-h-[1px] max-md:max-w-full' />
      <div className='flex flex-wrap gap-10 justify-between items-start mt-8 w-full max-md:max-w-full'>
        <p>© 2023 Aluma. All rights reserved.</p>
        <nav className='flex gap-6 items-start min-w-[240px]'>
          <a href='#/privacy' className='underline'>
            Privacy Policy
          </a>
          <a href='#/termsandcondition' className='underline'>
            Terms of Service
          </a>
        </nav>
      </div>
    </div>
  );
}

export default function FooterPage() {
  return (
    <footer className='flex overflow-hidden flex-col self-stretch px-16 py-20 mt-28 w-full bg-slate-900 max-md:px-5 max-md:mt-10 max-md:max-w-full'>
      <div className='flex flex-wrap gap-10 justify-center items-center pr-40 w-full max-md:pr-5 max-md:max-w-full'>
        <div className='flex flex-col grow shrink self-stretch my-auto min-w-[240px] w-[804px] max-md:max-w-full'>
          <Logo />
          <ContactInfo />
          <SocialIcons />
        </div>
        <div className='flex flex-col grow shrink self-end min-w-[240px] w-[242px]'>
          {/* <FooterLinks /> */}
          <PaymentOptions />
        </div>
      </div>
      <Copyright />
    </footer>
  );
}
