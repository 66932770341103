import React from 'react';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ImgSrc1 from '../../assets/aluma-web-img/1.png';
import ImgSrc2 from '../../assets/aluma-web-img/2.png';
import ImgSrc3 from '../../assets/aluma-web-img/3.png';

function ControlledCarousel({ imgSrcArr, index, handleSelect }) {
  return (
    <Carousel activeIndex={index} onSelect={handleSelect} controls={false}>
      {imgSrcArr.map((item, idx) => {
        return (
          <Carousel.Item>
            <img
              loading='lazy'
              src={item.src}
              alt='Main slider image'
              className='object-contain w-full aspect-[2.15] max-md:max-w-full'
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

const SliderDot = ({ active, handleClick }) => {
  const baseClasses =
    'flex shrink-0 self-stretch my-auto w-4 h-4 rounded-2xl border border-black border-solid';
  const activeClasses = active
    ? 'bg-green-500 border-[0.5px] w-[49px]'
    : 'bg-white';

  return (
    <button
      className={`${baseClasses} ${activeClasses}`}
      aria-label={active ? 'Current slide' : 'Go to slide'}
      tabIndex='0'
      onClick={handleClick}
    />
  );
};

const SliderArrow = ({ direction }) => {
  return (
    <button
      className='object-contain shrink-0 self-stretch my-auto w-1.5 aspect-[0.75]'
      aria-label={`${direction === 'left' ? 'Previous' : 'Next'} slide`}
    >
      <img
        loading='lazy'
        src={`http://b.io/ext_${direction === 'left' ? '2' : '3'}-`}
        alt=''
        className='w-full h-full'
      />
    </button>
  );
};

export default function TopSection() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const imgSrcArr = [
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/ab45b54f374bc9b63b4dcce4ece71f91067174072247eb4105cc111e7897dbca?apiKey=73c212f1d09443c4bd52c4e7c162d4e5&',
    },
    { src: ImgSrc1 },
    { src: ImgSrc2 },
    { src: ImgSrc3 },
  ];
  const dots = [
    { active: false },
    { active: false },
    { active: true },
    { active: false },
  ];

  return (
    <section className='flex flex-col'>
      <ControlledCarousel imgSrcArr={imgSrcArr} {...{ index, handleSelect }} />
      {/* <img
        loading='lazy'
        src='https://cdn.builder.io/api/v1/image/assets/TEMP/ab45b54f374bc9b63b4dcce4ece71f91067174072247eb4105cc111e7897dbca?apiKey=73c212f1d09443c4bd52c4e7c162d4e5&'
        alt='Main slider image'
        className='object-contain w-full aspect-[2.15] max-md:max-w-full'
      /> */}
      <nav
        className='flex gap-5 items-center self-center mt-2'
        aria-label='Image slider navigation'
      >
        <SliderArrow direction='left' />
        <div className='flex gap-2 items-center self-stretch my-auto'>
          {dots.map((dot, idx) => (
            <SliderDot
              key={idx}
              active={idx == index}
              handleClick={() => setIndex(idx)}
            />
          ))}
        </div>
        <SliderArrow direction='right' />
      </nav>
    </section>
  );
}
