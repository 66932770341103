import React from 'react';

function CongratulationsHeader() {
  return (
    <h1 className='text-7xl font-extralight tracking-tighter text-center max-md:max-w-full max-md:text-4xl'>
      Congratulations!
    </h1>
  );
}

function RewardAmount() {
  return (
    <div className='flex flex-col justify-center items-center max-md:mt-5 max-w-full text-2xl text-zinc-800 w-[340px] max-sm:mt-3'>
      <p>You will receive upto</p>
      <p className='mt-2 text-9xl text-stone-900 max-md:text-4xl'>₹100</p>
      <p className='mt-2'>in your Aluma wallet</p>
    </div>
  );
}

function CongratsComponent() {
  return (
    <main className='flex overflow-hidden flex-col px-1.5 mt-16 max-w-full bg-white border border-solid border-slate-300 rounded-[32px] shadow-[0px_4px_89px_rgba(234,234,234,0.5)] w-[954px] max-md:mt-10'>
      <section className='flex relative flex-col justify-center items-center px-20 py-24 min-h-[529px] max-md:px-5 max-md:max-w-full max-sm:min-h-[100px] max-sm:py-10'>
        <img
          loading='lazy'
          src='https://cdn.builder.io/api/v1/image/assets/TEMP/eec68080405b654da42a16e044f7eea8a002447a099d62c5ce2f2ee019c5b9a6?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
          alt=''
          className='object-cover absolute inset-0 size-full'
        />
        <div className='flex relative flex-col max-w-full w-[599px]'>
          <div className='flex flex-col items-center w-full max-md:max-w-full'>
            <CongratulationsHeader />
            <img
              loading='lazy'
              src='https://cdn.builder.io/api/v1/image/assets/TEMP/fa6d27365fef8219b61fd8bea6de8fb547d4e309140bec699925fba8fc133964?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
              alt=''
              className='object-contain mt-4 aspect-[55.56] w-[55px]'
            />
            <RewardAmount />
          </div>
        </div>
      </section>
    </main>
  );
}

export default CongratsComponent;
