import * as React from 'react';

function NavigationButton({
  icon,
  text,
  endIcon,
  variant = 'secondary',
  handleClick,
}) {
  const baseStyles =
    'flex gap-1.5 justify-center items-center self-stretch px-6 py-2.5 my-auto min-w-[240px] rounded-[41px] max-md:px-5';
  const variantStyles = {
    primary: 'border-2 border-amber-200 border-solid',
    secondary: 'border border-solid border-slate-900 text-slate-900',
  };

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]}`}
      aria-label={text}
      onClick={() => handleClick?.()}
    >
      <img
        loading='lazy'
        src={icon}
        alt=''
        className='object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]'
      />
      <span className='self-stretch my-auto tracking-tight'>{text}</span>
      {endIcon && (
        <img
          loading='lazy'
          src={endIcon}
          alt=''
          className='object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]'
        />
      )}
    </button>
  );
}

function NavigationLink({ text, handleClick }) {
  return (
    <div
      onClick={handleClick}
      className='self-stretch my-auto hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-900 cursor-pointer'
      aria-label={text}
    >
      {text}
    </div>
  );
}

const navigationLinks = [
  { text: 'Plans & Pricing', link: 'plans' },
  { text: 'Contact Us', link: 'contactus' },
  // { text: 'About Us' },
];

function HeaderPageNew() {
  return (
    <header className='flex flex-wrap gap-10 justify-between items-center px-16 py-6 w-full text-base bg-white shadow-[0px_16px_51px_rgba(0,0,0,0.1)] text-slate-900 max-md:px-5 max-md:max-w-full'>
      <img
        loading='lazy'
        src='https://cdn.builder.io/api/v1/image/assets/TEMP/1d6ba056522119084999b6a8130a44b812a2f5fac74370aaffefa47c5855aab1?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
        alt='Company Logo'
        className='object-contain shrink-0 self-stretch my-auto aspect-[4.08] w-[110px]'
      />
      <nav className='flex flex-wrap gap-8 items-center self-stretch my-auto min-w-[240px] max-md:max-w-full max-sm:hidden'>
        <NavigationButton
          icon='https://cdn.builder.io/api/v1/image/assets/TEMP/b1d42ddadc3573b692bd4302017997c77cba52bc8689cce771beb05edea380cc?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
          text='Unlimited haircut for a year in just ₹x99'
          endIcon='https://cdn.builder.io/api/v1/image/assets/TEMP/62227262fb476881ec36161d560df0beab0d9847b7dc6975558894aa7cc8daa4?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
          variant='primary'
          handleClick={() => {
            const elem = document.getElementById('plancards');
            elem?.scrollIntoView();
          }}
        />

        {navigationLinks.map((link, index) => (
          <NavigationLink
            key={index}
            text={link.text}
            handleClick={() => {
              const elem = document.getElementById(link.link);
              elem?.scrollIntoView();
            }}
          />
        ))}

        <NavigationButton
          icon='https://cdn.builder.io/api/v1/image/assets/TEMP/e3ec24743cf18b33f0d9c2217ebafb42d69582b19b9007d98a7c4ede58e127a7?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
          text='Become a Aluma Partner'
          variant='secondary'
          handleClick={() => {
            const elem = document.getElementById('contactus');
            elem?.scrollIntoView();
          }}
        />
      </nav>
    </header>
  );
}

export default HeaderPageNew;
