import React from 'react';

function PlanHeader() {
  return (
    <header className='flex flex-col items-center self-center max-w-full w-[797px]'>
      <div className='flex flex-col items-center w-full text-center'>
        <h2 className='text-base font-bold text-black tracking-[6.24px]'>
          PLANS
        </h2>
        <div className='flex flex-col mt-4 w-full max-sm:mt-2'>
          <h1 className='text-5xl font-bold text-green-500 leading-[58px] max-md:max-w-full max-md:text-4xl max-md:leading-[54px]'>
            Unlock Premium Access to Top Salons with Our Exclusive Plans
          </h1>
          <p className='self-center mt-4 text-lg leading-7 text-black max-md:max-w-full'>
            Enjoy easy access to basic, pro, and premium salons with our
            comprehensive plans. Receive top-quality services and exclusive
            benefits designed to meet all your grooming and pampering needs.
            Choose a plan that fits your lifestyle and elevate your salon
            experience today.
          </p>
        </div>
      </div>
    </header>
  );
}

function PlanToggle() {
  return (
    <div className='flex gap-3.5 items-center mt-12 text-base font-medium max-md:mt-10 justify-center'>
      <button className='gap-2 self-stretch px-8 py-2 tracking-tight text-white border border-lime-400 border-solid bg-[radial-gradient(163.05%_110.33%_at_0%_30.85%,#7ED957_0%,#20B364_100%)] rounded-[37px] max-md:px-5'>
        6 Months
      </button>
      <button className='gap-2 self-stretch px-8 py-2 bg-gray-200 rounded-2xl text-slate-900 max-md:px-5'>
        1 Year
      </button>
    </div>
  );
}

const planCards = [
  {
    src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/dd591eb50c93731a7bd860892eab36708aea9e938cd3a3d0d64bbcffa9e4e637?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
    alt: 'Plan card 1',
  },
  {
    src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/122fbafc7f1899ca0a863af0034defb59ab113190acfc7d2163ca717c1207f5c?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
    alt: 'Plan card 2',
  },
  {
    src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/da9b225fb4fd1a28abde4c65d3f214579b15770f8c3e4282161584b9fd01d122?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
    alt: 'Plan card 3',
  },
];

function PlanCards() {
  return (
    <div
      className='flex flex-wrap gap-10 items-center mt-16 w-full max-md:mt-10 max-md:max-w-full'
      id='plancards'
    >
      {planCards.map((card, index) => (
        <img
          key={index}
          loading='lazy'
          src={card.src}
          alt={card.alt}
          className='object-contain grow shrink self-stretch my-auto aspect-[0.64] min-w-[240px] w-[296px]'
        />
      ))}
    </div>
  );
}

export default function PlanSection() {
  return (
    <section
      className='flex overflow-hidden flex-col justify-center items-center px-20 py-16 bg-white max-md:px-5 max-md:pb-24 max-sm:py-10'
      id='plans'
    >
      <div className='flex flex-col w-full max-w-[1246px] max-md:max-w-full'>
        <PlanHeader />
        <PlanToggle />
        <PlanCards />
      </div>
    </section>
  );
}
