import React from 'react';
import { APP_STORE_URL, PLAY_STORE_URL } from '../../utils/appConstant';

function StepCard({ stepNumber, title, children }) {
  return (
    <div className='flex flex-col justify-center items-center min-w-[240px]'>
      <div className='flex flex-col justify-center items-center max-w-full text-center text-stone-900'>
        <h2 className='text-5xl max-md:text-4xl'>STEP {stepNumber}</h2>
        <p className='text-2xl tracking-tighter'>{title}</p>
      </div>
      {children}
    </div>
  );
}

function AppStoreButtons() {
  return (
    <div className='flex gap-4 items-center mt-3.5'>
      <a
        href={APP_STORE_URL}
        className='flex gap-2.5 justify-center items-center self-stretch px-9 py-3.5 my-auto rounded-lg border border-black border-solid min-h-[72px] w-[104px] max-md:px-5'
      >
        <img
          loading='lazy'
          src='https://cdn.builder.io/api/v1/image/assets/TEMP/7f22a44973d38f8c83ee291d10ff49b90e106befea4fa5a74f05b7fcf3e5f830?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
          alt='App Store'
          className='object-contain self-stretch my-auto w-9 aspect-[0.82]'
        />
      </a>
      <a
        href={PLAY_STORE_URL}
        className='flex gap-2.5 justify-center items-center self-stretch px-9 py-3.5 my-auto rounded-lg border border-black border-solid min-h-[72px] w-[107px] max-md:px-5'
      >
        <img
          loading='lazy'
          src='https://cdn.builder.io/api/v1/image/assets/TEMP/bbbfc961adebc61c7a361c4117f4457f21524714e302d6711594dfe3f8052666?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
          alt='Google Play'
          className='object-contain self-stretch my-auto aspect-[0.89] w-[39px]'
        />
      </a>
    </div>
  );
}

function ReferralCode({ code }) {
  return (
    <div className='flex flex-col mt-3.5 max-w-full whitespace-nowrap rounded-none w-[203px]'>
      <div
        className='flex flex-col justify-center px-3 py-2 w-full rounded-lg border border-slate-900'
        style={{ borderStyle: 'dashed' }}
      >
        <div className='flex gap-7 items-center'>
          <div className='self-stretch my-auto text-2xl font-light text-slate-900'>
            {code}
          </div>
          <button
            className='flex gap-3 items-center self-stretch my-auto text-xs text-green-500 border-slate-900'
            onClick={() => navigator.clipboard.writeText(code)}
            style={{
              borderLeft: '1px solid #0f172a70',
              borderStyle: 'dashed',
            }}
          >
            <span className='object-contain shrink-0 self-stretch my-auto w-px aspect-[0.02] stroke-[1px] stroke-slate-900'></span>
            <div className='flex flex-col self-stretch my-auto w-8'>
              <img
                loading='lazy'
                src='https://cdn.builder.io/api/v1/image/assets/TEMP/c7f600eb6073ce028ce5a8a6b2f8ce7a42a10094118332a49a1554a82b88a900?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
                alt=''
                className='object-contain self-center w-6 aspect-square'
              />
              <span>COPY</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

function WalletButton() {
  return (
    <button className='flex flex-col justify-center items-center px-px py-4 mt-3.5 max-w-full text-xl font-light tracking-wider text-black whitespace-nowrap rounded-lg border border-black border-solid min-h-[72px] w-[222px]'>
      <div className='flex gap-3 justify-between max-w-full w-[172px]'>
        <img
          loading='lazy'
          src='https://cdn.builder.io/api/v1/image/assets/TEMP/b2c4dacf1536c46e0a4bac3d9a52a6f8108de596af5122a610410f12da1d2e9b?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
          alt=''
          className='object-contain shrink-0 aspect-square w-[38px]'
        />
        <span className='my-auto'>
          <span style={{ fontWeight: '900' }}>Aluma</span> Wallet
        </span>
      </div>
    </button>
  );
}

function DownloadSteps() {
  return (
    <main
      className='max-sm:flex-wrap flex gap-10 items-start mt-20 max-md:mt-10 max-md:max-w-full max-sm:px-8 md:px-[70px]'
      //   style={{
      //     paddingLeft: '70px',
      //     paddingRight: '70px',
      //   }}
    >
      <StepCard
        stepNumber={1}
        title={
          <>
            Download{' '}
            <span className='font-bold text-green-800 uppercase tracking-[2.88px]'>
              Aluma
            </span>{' '}
            on play store and app store
          </>
        }
      >
        <AppStoreButtons />
      </StepCard>

      <StepCard
        stepNumber={2}
        title='Copy this referral code and paste in Aluma app'
      >
        <ReferralCode code='FIRST50' />
      </StepCard>

      <StepCard
        stepNumber={3}
        title={
          <>
            <span className='italic font-semibold'>Congratulations!</span> You
            will get up to{' '}
            <span className='italic font-bold text-black'>
              {' '}
              ₹100 in your Aluma wallet{' '}
            </span>
          </>
        }
      >
        <WalletButton />
      </StepCard>
    </main>
  );
}

export default DownloadSteps;
