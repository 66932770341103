// import Section1 from './Section1';
// import Section2 from './Section2';
// import Section3 from './Section3';

import FooterPage from './components/Footer';
import HeaderPage from './components/HeaderPage';
import CongratsComponent from './CongratsSection';
import DownloadSteps from './StepSection';

export default function OffersPage() {
  return (
    <>
      <HeaderPage />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className='max-sm:px-2'
      >
        <CongratsComponent />
        <div
          style={{
            backgroundColor: 'rgba(255, 57, 58, 0.12)',
            borderRadius: '44px',
          }}
          className='max-sm:px-3 max-sm:py-2 max-sm:mt-4 max-sm:font-normal md:mt-10 md:px-10 md:py-3 md:font-bold'
        >
          <div
            style={{ fontWeight: 400, color: 'red' }}
            className='max-sm:text-1xl md:text-3xl'
          >
            To claim this offer, please follow these steps:
          </div>
        </div>
        <DownloadSteps />
      </div>
      <FooterPage />
      {/* <Section1 />
      <Section2 />
      <Section3 /> */}
    </>
  );
}
