import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { submitOnboarding } from '../../api';
import { Spinner } from 'react-bootstrap';
import TopSection from './TopSection';
import PlanSection from './PlanSection';
import { APP_STORE_URL, PLAY_STORE_URL } from '../../utils/appConstant';

function DownloadButton({ platform, icon, color, border }) {
  return (
    <div
      className={`flex gap-4 justify-center items-center self-stretch p-3 my-auto rounded-2xl border-2 ${border} border-solid min-w-[240px] ${color} cursor-pointer`}
      onClick={() => {
        const urlToOpen = platform == 'IOS' ? APP_STORE_URL : PLAY_STORE_URL;
        window.open(urlToOpen);
      }}
    >
      <img
        loading='lazy'
        src={icon}
        alt={`${platform} icon`}
        className='object-contain shrink-0 self-stretch my-auto aspect-square w-[74px]'
      />
      <div className='self-stretch my-auto'>
        Download for <span className='font-bold'>{platform}</span>
      </div>
    </div>
  );
}

function RecyclingStep({ step, title, description, image }) {
  return (
    <div className='flex overflow-hidden items-start self-stretch pt-9 pl-6 my-auto bg-white rounded-2xl min-w-[240px] w-[387px]'>
      <div className='flex z-10 flex-col my-auto mr-0'>
        <div className='text-2xl font-semibold tracking-tight text-stone-300'>
          {step}
        </div>
        <div className='flex flex-col mt-1.5 w-full'>
          <div className='text-4xl font-bold tracking-tighter text-green-500'>
            {title}
          </div>
          <div className='text-base tracking-tight opacity-60 text-slate-900'>
            {description}
          </div>
        </div>
      </div>
      <img
        loading='lazy'
        src={image}
        alt={`${title} illustration`}
        className='object-contain shrink-0 self-start max-w-full aspect-[1.03] w-[216px] max-sm:w-[125px] max-sm:self-center'
      />
    </div>
  );
}

function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const initialValues = {
    email: '',
    name: '',
    message: '',
  };
  const {
    setError,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    register,
  } = useForm({
    mode: 'onTouched',
    // reValidateMode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  });
  const [validated, setValidated] = useState(false);

  const onSubmit = async (data) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    setLoading(true);
    console.log('data', data);
    try {
      const data2 = await submitOnboarding(data);
      console.log('data2', data2);
      reset(initialValues);
      setSubmitted(true);
    } catch (err) {
      console.log('err', err);
    }
    setLoading(false);

    setValidated(true);
  };
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setValidated(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  return (
    <section
      id='contactus'
      className='flex overflow-hidden flex-col justify-center max-md:px-5 py-24 w-full bg-white max-md:max-w-full max-sm:py-0 max-sm:px-2 pb-0'
    >
      <div className='flex flex-wrap gap-10 justify-center items-center pr-4 max-md:max-w-full'>
        <div className='flex flex-col justify-center self-stretch my-auto min-w-[240px] w-[624px] max-md:max-w-full'>
          <div className='flex flex-col w-full max-md:max-w-full max-sm:text-center'>
            <p className='text-base font-semibold tracking-tight text-green-500'>
              Where Beauty Shines Bright
            </p>
            <div className='flex flex-col w-full text-black max-md:max-w-full'>
              <h2 className='text-5xl font-medium tracking-tighter leading-tight max-md:max-w-full max-md:text-4xl'>
                Contact us
              </h2>
              <p className='mt-2 text-lg font-light tracking-tight max-md:max-w-full'>
                We'd love to hear from you and make your next visit to Aluma
                Salon exceptional.
              </p>
            </div>
          </div>
          <form
            className='flex flex-col mt-8 w-full text-base tracking-tight max-md:max-w-full'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='flex flex-col w-full font-medium text-black whitespace-nowrap max-md:max-w-full'>
              <label htmlFor='name' className='max-md:max-w-full'>
                Name
              </label>
              <input
                id='name'
                type='text'
                className='pl-2 flex gap-2 py-3 mt-2 w-full bg-white rounded-lg border border-solid border-stone-300 min-h-[48px] max-md:max-w-full'
                aria-label='Name'
                style={errors['name'] ? { borderColor: 'red !important' } : {}}
                {...register('name', { required: 'Required' })}
              />
            </div>
            <div className='flex flex-col mt-6 w-full font-medium text-black whitespace-nowrap max-md:max-w-full'>
              <label htmlFor='email' className='max-md:max-w-full'>
                Email
              </label>
              <input
                id='email'
                type='email'
                className='pl-2 flex gap-2 py-3 mt-2 w-full bg-white rounded-lg border border-solid border-stone-300 min-h-[48px] max-md:max-w-full'
                aria-label='Email'
                style={errors['email'] ? { borderColor: 'red !important' } : {}}
                {...register('email', { required: 'Required' })}
              />
            </div>
            <div className='flex flex-col mt-6 w-full max-md:max-w-full'>
              <label
                htmlFor='message'
                className='font-medium text-black max-md:max-w-full'
              >
                Message
              </label>
              <textarea
                id='message'
                className='flex-1 shrink px-3 pt-3 pb-36 mt-2 w-full font-light bg-white rounded-lg border border-solid border-stone-300 min-h-[180px] text-stone-500 max-md:pb-24 max-md:max-w-full'
                placeholder='Type your message...'
                aria-label='Message'
                style={
                  errors['message'] ? { borderColor: 'red !important' } : {}
                }
                {...register('message', { required: 'Required' })}
              ></textarea>
            </div>
            <div className='flex gap-2 items-center self-start pb-4 mt-6 text-sm tracking-tight text-black'>
              <input
                type='checkbox'
                id='terms'
                className='shrink-0 self-stretch my-auto bg-white rounded border border-black border-solid h-[18px] w-[18px]'
              />
              <label htmlFor='terms' className='self-stretch my-auto'>
                <span className='font-light'>I accept the </span>
                <span className='font-light text-black'>Terms</span>
              </label>
            </div>
            <button
              type={'submit'}
              disabled={submitted}
              className='gap-2 self-start px-12 py-2.5 mt-6 text-xl font-medium tracking-tight text-white whitespace-nowrap rounded-2xl border-lime-400 border-solid bg-[radial-gradient(163.05%_110.33%_at_0%_30.85%,#7ED957_0%,#20B364_100%)] border-[1.002px] max-md:px-5 max-sm:w-full'
            >
              {loading ? (
                <Spinner animation='border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
              ) : (
                <>
                  {submitted
                    ? 'Your request is submitted successfully'
                    : 'Submit'}
                </>
              )}
            </button>
          </form>
        </div>
        <img
          loading='lazy'
          src='https://cdn.builder.io/api/v1/image/assets/TEMP/c7549bd87f1981b8a41165c7096b8a8dcfbb91418f3c5f20d6e16cc207f09d49?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
          alt='Contact illustration'
          className='object-contain self-stretch my-auto aspect-[0.85] min-w-[240px] rounded-[42px] w-[604px] max-md:max-w-full'
        />
      </div>
    </section>
  );
}

export default function MainContent() {
  const downloadButtons = [
    {
      platform: 'IOS',
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/14dd3bb2146d9833138ed21a5a78b6ffb75edf46d61a85a87f449ce669dbc610?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
      color: 'text-slate-900',
      border: 'border-black',
    },
    {
      platform: 'Android',
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/a053768871cd601155e4ed3d7010010b9cc36202b090fa062d561e5b7d246fb1?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
      color: 'text-green-500',
      border: 'border-green-400',
    },
  ];

  const recyclingSteps = [
    {
      step: 'STEP 1',
      title: 'Collection',
      description:
        'We start by collecting human hair from our salons—what was once waste now becomes a resource.',
      image:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/6088815756a4798895ca0fe7147d59f2fdcdd30ed25362deca6245776bc20fb2?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
    },
    {
      step: 'STEP 2',
      title: 'Recycling',
      description:
        'The hair is rich in nitrogen, a vital nutrient for plants. We recycle it into nutrient-rich fertilizer, perfect for soil enrichment.',
      image:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/c049cb9e46fdb8e22658ec152a4883bded4b211002cac58473bc2edc132e0288?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
    },
    {
      step: 'STEP 3',
      title: 'Planting',
      description:
        'Our mission is to plant more trees using this natural fertilizer, contributing to a greener planet.',
      image:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/bd732ad50c0ea98b99064fcd1b3345b8563f959b1c7713962db0a7f0d84d9314?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5',
    },
  ];

  return (
    <main className='flex flex-col w-full max-md:max-w-full'>
      <TopSection />
      <PlanSection />
      {/* <img
        loading='lazy'
        src='https://cdn.builder.io/api/v1/image/assets/TEMP/644297e6b17a91fe24fdce92bc1afb8d50095a15a984d5cdca7dd6673dc0ffa0?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
        alt=''
        className='object-contain w-full aspect-[0.79] max-md:max-w-full'
      />*/}
      <img
        loading='lazy'
        src='https://cdn.builder.io/api/v1/image/assets/TEMP/cd3e6b43555fc526f9e2192749d74bc8997e2a15141ea669c39a2b7b8f1d18a5?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
        alt=''
        className='object-contain w-full aspect-[1.49] max-md:max-w-full'
      />
      <section className='flex flex-col w-full max-md:max-w-full'>
        <div className='flex flex-col w-full max-md:max-w-full'>
          <div className='flex flex-col items-center self-center max-md:max-w-full'>
            <div className='flex flex-col justify-center items-center capitalize max-md:max-w-full'>
              <h1 className='text-5xl font-bold leading-tight text-center text-green-500 max-md:max-w-full max-md:text-4xl'>
                Download our app now!!!
              </h1>
              <p className='mt-2 text-2xl font-medium tracking-tighter leading-none text-right text-slate-900'>
                Available in Mobile version
              </p>
            </div>
            <div className='flex gap-10 items-center mt-12 text-4xl text-center max-md:mt-10 max-md:max-w-full'>
              <div className='flex flex-wrap gap-6 items-center self-stretch my-auto min-w-[240px] max-md:max-w-full max-sm:px-1'>
                {downloadButtons.map((button, index) => (
                  <DownloadButton key={index} {...button} />
                ))}
              </div>
            </div>
          </div>
          <img
            loading='lazy'
            src='https://cdn.builder.io/api/v1/image/assets/TEMP/12f0cf129ea79f637a5419ad777c8fd95bce2d3b2c37b60dc680b53cee9bf4a8?placeholderIfAbsent=true&apiKey=73c212f1d09443c4bd52c4e7c162d4e5'
            alt=''
            className='object-contain mt-1 w-full aspect-[1.92] max-md:mt-1 max-md:max-w-full'
          />
        </div>
        <section
          className='flex flex-col justify-center px-20 py-4 mt-0 w-full bg-white max-md:px-5 max-md:mt-10 max-md:max-w-full max-sm:pt-0'
          id='services'
        >
          <div className='flex flex-col items-center px-9 py-7 bg-[radial-gradient(163.05%_110.33%_at_0%_30.85%,#7ED957_0%,#20B364_100%)] rounded-[46.154px] max-md:px-5 max-md:max-w-full'>
            <h2 className='text-base font-bold text-center text-white tracking-[6.4px]'>
              HIGHLIGHTS
            </h2>
            <p className='mt-14 text-4xl font-medium text-white max-md:mt-10 max-md:max-w-full max-sm:text-2xl max-sm:mt-4'>
              Transforming Beauty into Green
            </p>
            <p className='mt-1.5 ml-14 text-6xl font-semibold tracking-tighter text-white max-md:max-w-full max-md:text-4xl max-sm:ml-0 max-sm:text-2xl'>
              Aluma's Eco-Friendly Mission
            </p>
            <p className='gap-2.5 max-md:py-4 pr-7 pl-7 mt-3.5 ml-0 text-2xl font-light text-white border border-white border-solid bg-white bg-opacity-10 rounded-[69px] max-md:px-5  max-sm:ml-0 max-md:w-fit max-md:self-center max-sm:py-2'>
              A Sustainable Future through Hair Recycling
            </p>
            <div className='flex max-md:flex-nowrap gap-6 items-center self-stretch mt-20 max-md:mt-10 max-md:max-w-full max-sm:flex-wrap'>
              {recyclingSteps.map((step, index) => (
                <RecyclingStep key={index} {...step} />
              ))}
            </div>
          </div>
        </section>
      </section>
      <ContactForm />
    </main>
  );
}
